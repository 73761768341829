/*!
* Copyright © 2025 FBSPL. All rights reserved.
* This script is proprietary and confidential.
* Unauthorized copying, distribution, or modification is strictly prohibited.
*/
export const environment = {
  production: true,
  apiUrl: 'https://admin.fbspl.com',
  cdnUrl: 'https://d1585q1wairh36.cloudfront.net/',
  mainURL:'https://www.fbspl.com'
};
